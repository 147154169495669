import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <IframeYouTube width="1280" height="720" id="AwGl-Nma_-s" />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <p>
        2020年ドバイ国際博覧会の日本館において、極微細ミストと位置情報即位の先端技術を組み合わせた全く新しい体験型インスタレーションを実現。 ディレクター<Link to="/members/ochiai-masao/">落合正夫</Link>が日本館の全体演出を担当し、日本館のコンセプトである「Where ideas meet アイディアの出会い」から、日本の出会いの歴史を体感する展示ストーリーを構築。
      </p>
    );
  };

  const summaryData = {
    scopes: ['Produce', 'Art Direction', 'Experience Design', 'Space Design', 'Video Production'],
    awards: ['BIE Prizes and Awards', 'Exhibition Design Gold Award'],
    outline: OutlineContent
  };

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
    >
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(0, 4)} column={1} />
        <WorkDetailImages images={imageNodes.slice(4, 8)} column={2} />
        <WorkDetailImages images={imageNodes.slice(8, 10)} column={1} />
      </WorkDetailImageGallery>
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/dubai_expo"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/dubai_expo.*"}) {
      publicURL
    }
  }
`;
